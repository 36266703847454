import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { graphql } from 'gatsby';

import { Container, Content, mobileThresholdPixels } from '../components/home/v2/styledComponents';
import Header from '../components/home/v3/Header';
import BenefitsBanner from '../components/home/v3/BenefitsBanner';
import ACouturierAtHome from '../components/home/v3/ACouturierAtHome';
import FeedbacksBannerAds from '../components/home/v3/FeedbacksBannerAds';
import ReferrerBanner from '../components/home/ReferrerBanner';
import FAQAds from '../components/home/v3/FAQAds';
import MenuBar from '../components/home/MenuBar';
import PartnersPager from '../components/home/v3/PartnersPager';
import FootNote from '../components/LandingPage/FootNote';
import Footer from '../components/home/v3/Footer';
import Layout from '../layouts/index';

import { getAlterationAltTagMainLanding } from './LandingLocalityFormatter';

const StyledContainer = styled(Container)`
  padding-top: 70px;
  @media (max-width: ${mobileThresholdPixels}) {
    padding-top: 43px;
  }
`;

class LandingMainPageTemplate extends React.Component {
  componentDidMount() {
    if (typeof window === 'undefined') return;
    window.scrollTo(0, 0);
    window.onbeforeunload = null;
  }

  render() {
    const { data: { landingsMainsJson }, referrer } = this.props;
    const { slug, fabric, prefilledSteps, footNote, breadcrumb } = landingsMainsJson;
    const title = fabric === 'curtains' ?
      'Faites venir un couturier chez vous pour retoucher vos rideaux et voilages' :
      'Nos couturiers se déplacent chez vous, pour vos retouches';
    return (
      <Layout routeSlug={slug}>
        <StyledContainer>
          <Content full>
            {referrer && <ReferrerBanner referrer={referrer} />}
            <MenuBar />
            <Header
              title={title}
              hideNote
              smallTitle
              titleLeft
              curtainsPhoto={fabric === 'curtains'}
              curtainsNote={fabric === 'curtains'}
              curtainsVersion={fabric === 'curtains'}
              prefilledSteps={prefilledSteps}
            />
            <BenefitsBanner altTag={getAlterationAltTagMainLanding(slug)} />
            <ACouturierAtHome curtainsVersion={fabric === 'curtains'} prefilledSteps={prefilledSteps} />
            <FeedbacksBannerAds curtainsVersion={fabric === 'curtains'} />
            <FAQAds curtainsVersion={fabric === 'curtains'} prefilledSteps={prefilledSteps} isSEO />
            <PartnersPager curtainsVersion={fabric === 'curtains'} />
            <FootNote
              title={footNote.title}
              paragraph={footNote.paragraph}
              breadcrumbIntro={footNote.breadcrumbIntro.paragraph}
              breadcrumbIntroLinks={footNote.breadcrumbIntro.links}
              links={footNote.links}
              showFAQ={footNote.showFAQ}
              itemListElement={breadcrumb}
            />
            <Footer />
          </Content>
        </StyledContainer>
      </Layout>
    );
  }
}

LandingMainPageTemplate.propTypes = {
  data: PropTypes.shape({
    landingsMainsJson: PropTypes.shape({}).isRequired,
  }).isRequired,
  referrer: PropTypes.shape({}),
};

LandingMainPageTemplate.defaultProps = {
  referrer: null,
};
export default LandingMainPageTemplate;

export const landingMainPageQuery = graphql`
query landingsMainsQuery($path: String!) {
  landingsMainsJson(path: { eq: $path }) {
    slug
    path
    prefilledSteps {
      selectedCategory
      selectedPiece
      selectedTypeOfWork
    }
    fabric
    footNote {
      title
      paragraph
      links
      breadcrumbIntro {
        paragraph
        links
      }
      showFAQ
    }
    breadcrumb {
      name
      item
    }
  }
}`;
